import { useState } from "react";
import {
  ContactUsDialog,
  ContactUsDialogProps,
} from "@/components/Modal/ContactUsDialog";
import { mcn } from "@/utils";
import { useBaseContext } from "@/components/BaseContext";
import { useProfile } from "@/components/HeaderMain/useProfile";
import { OrganisationBase } from "@/server/backend-api/organisation";
import { ContactFormSetting } from "@/server/backend-api/generated";
import { LearningOpportunityName } from "@/server/backend-api";

interface Props {
  text: string;
  className?: string;
  formProps?: ContactUsDialogProps["formProps"];
  memberUniversities?: OrganisationBase[];
  contactFormSettings?: ContactFormSetting[];
  learningOppNames?: LearningOpportunityName[];
}

export function ContactUsButton(props: Props) {
  const [showForm, setShowForm] = useState(false);
  const base = useBaseContext();
  const { profile } = useProfile();

  let memberUniversities = new Array<OrganisationBase>();
  if( props.memberUniversities != null ) {
    memberUniversities = props.memberUniversities;
  } else if(base.memberUniversities != null) {
    memberUniversities = base.memberUniversities;
  }

  let contactFormSettings = new Array<ContactFormSetting>();
  if( props.contactFormSettings != null ) {
    contactFormSettings = props.contactFormSettings;
  } else if(base.memberUniversities != null) {
    contactFormSettings = base.contactFormSettings;
  }

  let learningOppNames = new Array<LearningOpportunityName>();
  if( props.learningOppNames != null ) {
    learningOppNames = props.learningOppNames;
  } else if(base.learningOppNames != null) {
    learningOppNames = base.learningOppNames;
  }

  return (
    <>
      <ContactUsDialog
        universities={memberUniversities}
        supportSettings={contactFormSettings}
        learningOppNames={learningOppNames}
        show={showForm}
        onClose={() => setShowForm(false)}
        formProps={props.formProps}
        profile={profile ?? undefined}
      />

      <button
        onClick={() => setShowForm(true)}
        className={mcn(
          "text-style-link1 cursor-pointer text-left",
          props.className
        )}
      >
        {props.text}
      </button>
    </>
  );
}
