import {
  formatDate,
  formatPeriod,
  formatPeriodIso,
  getDateOrNull,
} from "@/utils/dateUtils";
import { ServerRequest } from "../../types";
import { getAuthStatus } from "../getAuthStatus";
import { createBackendApiClient } from "./createBackendApiClient";
import {
  LearningActivity,
  LearningOpportunity as ApiLearningOpportunity,
  LearningOpportunityBase as ApiLearningOpportunityBase,
  LearningOpportunityLearner,
  LearningOpportunityPerson,
  LearningOpportunityStatus,
  LearningOpportunityType,
  LearningOpportunityUserStatus,
  LearningOutcome,
  StudyFormat,
  Tag,
} from "./generated";
import {
  mapUserAdmissionData,
  UserAdmissionData,
  UserAdmissionDataWithMicroCredentials,
} from "./learningOpportunityUserAdmission";
import { createImageUrl } from "./mapHelpers";
import { mapOrganisationBase, OrganisationBase } from "./organisation";
import { buildChallengeUrl, buildMicroModuleUrl } from "@/utils/url";
import dayjs from "dayjs";

/**
 * Minimal information about a learning opportunity.
 * For dropdown, lists, search etc.
 */
export interface LearningOpportunityName {
  id: string;
  name: string;
  studyPeriod: string;
  studyPeriodShort: string;
}

export type LearningOpportunityListItem = {
  type: LearningOpportunityType;
  id: string;
  url: string;
  title: string | null;
  status: LearningOpportunityStatus | null;
  imageUri: string | null;
  imageUriSmall: string | null;
  studyPeriodShort: string | null;
  studyPeriod: string | null;
  studyPeriodIso: string | null;
  userStatus: LearningOpportunityUserStatus | null;
  hostingUniversityId: string | null;
  hostingUniversityName: string | null;
  ects: number | null;
  userAdmissionData: UserAdmissionData | null;
  studyFormat: StudyFormat | null;
  teamsAccessDate: string | null;
  topics: LearningOpportunityTopic[];
  volumeOfLearning: number | null;
  isPastStartDate: boolean | null;
  tags: Tag[] | null;
};

export interface LearningOpportunityBaseWithMicroCredentials
  extends LearningOpportunityListItem {
  userAdmissionData: UserAdmissionDataWithMicroCredentials | null;

  /**
   * Indices whether micro credentials will be generated for students of this LO.
   */
  generateMicroCredentials: boolean;
  generateDigitalCredentials: boolean;
}

export type TeamMember = {
  displayName: string;
  userId: string | null;
  imageUrl: string | null;
};

export interface LearningOpportunityTopic {
  id: string;
  name: string | null;
}

export type LearningOpportunity = LearningOpportunityListItem & {
  subTitle: string | null;
  introduction: string | null;
  description: string | null;
  participantInformation: string | null;
  applicationPeriod: string | null;
  ects: number | null;
  hostingUniversity: OrganisationBase | null;
  challengeProviderOrganisations: OrganisationBase[];
  associatedPartnerOrganisations: OrganisationBase[];
  lmsUrl: string | null;
  eciuLmsEnabled: boolean;
  pace: number | null;
  teamchers: TeamMember[];
  learners: TeamMember[];
  admissionInformation: string | null;
  admissionProcessInformation: string | null;
  admissionDocumentTypes:
    | {
        id: string;
        name: string;
        description?: string | null;
      }[]
    | null;

  simplifiedAdmissionProcess: boolean | null;
  simplifiedAdmissionProcessWithNoDocuments: boolean | null;
  simplifiedAdmissionProcessAndIsFull: boolean | null;
  learningOutcomes: LearningOutcome[];
  learningActivities: LearningActivity[];
};

export type Challenge = {
  type: LearningOpportunityType.Challenge;
} & LearningOpportunity;
export type ChallengeListItem = {
  type: LearningOpportunityType.Challenge;
} & LearningOpportunityListItem;

export type MicroModule = {
  type: LearningOpportunityType.MicroModule;
} & LearningOpportunity;
export type MicroModuleListItem = {
  type: LearningOpportunityType.MicroModule;
} & LearningOpportunityListItem;

/** Get LO list */
export const getChallenges = async (): Promise<ChallengeListItem[]> => {
  const data = await getLearningOpportunities();
  return data
    .filter((x) => x.type === LearningOpportunityType.Challenge)
    .map((x) => x as ChallengeListItem);
};

export const getMicroModules = async (): Promise<MicroModuleListItem[]> => {
  const data = await getLearningOpportunities();
  return data
    .filter((x) => x.type === LearningOpportunityType.MicroModule)
    .map((x) => x as MicroModuleListItem);
};

export const getLearningOpportunities = async (
  request?: ServerRequest
): Promise<LearningOpportunityListItem[]> => {
  const auth = request ? getAuthStatus(request) : undefined;
  const client = createBackendApiClient(auth?.token);
  const data = await client.learningOpportunity_GetAll();
  return data.filter((x) => !!x.type).map(mapLearningOpportunityListItem);
};

/** Get one LO */
export const getChallenge = async (id: string, userRequest?: ServerRequest) => {
  const lo = await getLearningOpportunity(id, userRequest);
  if (lo.type !== LearningOpportunityType.Challenge)
    throw new Error("LO type mismatch");
  else return lo as Challenge;
};

export const getMicroModule = async (
  id: string,
  userRequest?: ServerRequest
) => {
  const lo = await getLearningOpportunity(id, userRequest);
  if (lo.type !== LearningOpportunityType.MicroModule)
    throw new Error("LO type mismatch");
  else return lo as MicroModule;
};

export const getLearningOpportunity = async (
  id: string,
  userRequest?: ServerRequest
): Promise<LearningOpportunity> => {
  const auth = userRequest ? getAuthStatus(userRequest) : undefined;
  const client = createBackendApiClient(auth?.token);
  const res = await client.learningOpportunity_GetById(id);
  return mapLearningOpportunity(res);
};

/** mapping functions */
const mapPerson = (
  person: LearningOpportunityLearner | LearningOpportunityPerson
) => {
  return {
    displayName: `${person.firstName} ${person.lastName}`,
    userId: person.azureAdUserId,
    imageUrl: !person.azureAdUserId
      ? null
      : `/api/profilePhoto/${encodeURIComponent(person.azureAdUserId)}`,
  };
};

export const mapLearningOpportunityList = (
  list: ApiLearningOpportunityBase[],
  filterOnStatus?: LearningOpportunityStatus[]
) => {
  const loTypesToInclude = [
    LearningOpportunityType.Challenge,
    LearningOpportunityType.MicroModule,
  ];

  return list
    .filter((x) => x.type && loTypesToInclude.includes(x.type))
    .filter(
      (x) => !filterOnStatus || (x.status && filterOnStatus.includes(x.status))
    )
    .map(mapLearningOpportunityListItem);
};

export const mapLearningOpportunityListItem = (
  data: ApiLearningOpportunityBase
): LearningOpportunityListItem => {
  const typeSpecific = mapTypeSpecific(data);
  if (!typeSpecific) throw new Error("invalid type LO type");

  const startDate = getDateOrNull(data.startDate);
  let isPastStartDate: boolean | null = null;
  const now = dayjs();
  if (startDate && now.isAfter(startDate)) {
    isPastStartDate = true;
  }

  return {
    ...data,
    type: typeSpecific.type,
    url: typeSpecific.url,
    title: data.name,
    status: data.status,
    imageUri: createImageUrl(data.imagesPath, 280, 280),
    imageUriSmall: createImageUrl(data.imagesPath, 40, 40),
    studyPeriod: formatPeriod(
      "long",
      getDateOrNull(data.startDate),
      getDateOrNull(data.endDate)
    ),
    studyPeriodShort: formatPeriod(
      "short",
      getDateOrNull(data.startDate),
      getDateOrNull(data.endDate)
    ),
    studyPeriodIso: formatPeriodIso(
      getDateOrNull(data.startDate),
      getDateOrNull(data.endDate)
    ),
    userStatus: data.userData?.status ?? null,
    hostingUniversityId: data.hostingUniversity
      ? data.hostingUniversity.id
      : null,
    hostingUniversityName: data.hostingUniversity?.name ?? null,
    userAdmissionData: mapUserAdmissionData(data.userData),
    teamsAccessDate: formatDate("long", data.teamsAccessDate, true) ?? null,
    topics:
      data.topics === null
        ? []
        : data.topics.map((x) => ({
            id: x.id,
            name: x.name,
          })),
    isPastStartDate: isPastStartDate,
    tags:
      data.tags == undefined || data.tags === null
      ? []
      : data.tags.map((x) => ({
          id: x.id,
          name: x.name,
        })),
  };
};

export const mapLearningOpportunity = (
  data: ApiLearningOpportunity
): LearningOpportunity => {
  const listItemProperties = mapLearningOpportunityListItem(data);

  return {
    ...listItemProperties,
    subTitle: data.subTitle,
    introduction: data.introduction,
    description: data.description,
    participantInformation: data.participantInformation,
    applicationPeriod: formatPeriod(
      "long",
      getDateOrNull(data.applicationPeriodStartDate),
      getDateOrNull(data.applicationPeriodEndDate)
    ),
    studyPeriod: formatPeriod(
      "long",
      getDateOrNull(data.startDate),
      getDateOrNull(data.endDate)
    ),
    studyFormat: data.studyFormat,
    hostingUniversity: data.hostingUniversity
      ? mapOrganisationBase(data.hostingUniversity)
      : null,
    associatedPartnerOrganisations:
      data.associatedPartnerOrganisations.map(mapOrganisationBase),
    challengeProviderOrganisations:
      data.challengeProviderOrganisations.map(mapOrganisationBase),
    ects: data.ects,
    lmsUrl: data.lmsUrl,
    eciuLmsEnabled: data.eciulmsEnabled ?? false,
    pace: data.pace,
    teamchers: data.teamchers.map(mapPerson),
    learners: data.learners.map(mapPerson),

    admissionInformation: data.admissionInformation ?? null,
    admissionProcessInformation: data.admissionProcessInformation ?? null,
    admissionDocumentTypes:
      data.documentTypes?.map((x) => ({
        id: x.id ?? "",
        name: x.name ?? "",
        description: x.description,
      })) ?? [],

    simplifiedAdmissionProcess: data.simplifiedAdmissionProcess,
    simplifiedAdmissionProcessAndIsFull:
      data.usesSimplifiedAdmissionProcessAndAdmissionIsFull,
    simplifiedAdmissionProcessWithNoDocuments:
      data.simplifiedAdmissionProcess === true &&
      data.documentTypes?.length === 0,
    learningOutcomes: data.learningOutcomes ?? [],
    learningActivities: data.learningActivities ?? [],
  };
};

export const mapTypeSpecific = (data: ApiLearningOpportunityBase) => {
  switch (data.type) {
    case LearningOpportunityType.MicroModule:
      return {
        type: LearningOpportunityType.MicroModule as const,
        url: buildMicroModuleUrl(data.id, data.name),
      };
    case LearningOpportunityType.Challenge:
      return {
        type: LearningOpportunityType.Challenge as const,
        url: buildChallengeUrl(data.id, data.name),
      };
  }
};

function statusSortPriority(status: LearningOpportunityStatus | null) {
  switch (status) {
    case LearningOpportunityStatus.OpenForApplication:
      return 0;
    case LearningOpportunityStatus.ComingSoon:
      return 1;
    case LearningOpportunityStatus.ClosedForApplication:
      return 2;
    default:
      return 3;
  }
}

export function sortLearningOpportunitiesByStatus(
  learningOpps: LearningOpportunityListItem[]
) {
  const _copy = [...learningOpps];
  return _copy.sort((left, right) => {
    return statusSortPriority(left.status) - statusSortPriority(right.status);
  });
}
