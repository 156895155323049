export class Assert {
  static check(condition: boolean, conditionStr?: string): asserts condition {
    if (condition === false) {
      throw new Error(
        "Expected condition to be true" +
          (conditionStr ? `: '${conditionStr}'` : ".")
      );
    }
  }

  static notNull<T>(
    value: T | null | undefined,
    message?: string
  ): asserts value is T {
    Assert.check(value != null, message ?? "Expected value to be not null.");
  }

  static notImplemented(message?: string) {
    throw new Error(message ?? "Not implemented.");
  }
}
