import { Assert } from "@/utils/assert";
import {
  LearningOpportunityStatus,
  LearningOpportunityType,
  LearningOpportunityUserStatus,
  Level,
  StudyFormat,
} from "./generated";

export const studyFormatToString = (
  studyFormat: StudyFormat | null
): string => {
  switch (studyFormat) {
    case StudyFormat.Campus:
      return "Campus";
    case StudyFormat.Hybrid:
      return "Hybrid";
    case StudyFormat.Online:
      return "Online";
    case StudyFormat.Blended:
      return "Blended";
    default:
      return "-";
  }
};

export const statusToString = (
  value: LearningOpportunityStatus | null
): string => {
  switch (value) {
    case LearningOpportunityStatus.ComingSoon:
      return "Coming soon";
    case LearningOpportunityStatus.OpenForApplication:
      return "Open for application";
    case LearningOpportunityStatus.ClosedForApplication:
      return "Closed for application";
    case LearningOpportunityStatus.Finished:
      return "Finished";
    case LearningOpportunityStatus.Unknown:
      return "Unknown";
    default:
      Assert.notImplemented(`Unknown LearningOpportunityStatus = ${value}`);
      return "-";
  }
};

export function learningOppUserStatusToString(
  value: LearningOpportunityUserStatus | null
) {
  switch (value) {
    case LearningOpportunityUserStatus.LearnerApplied:
      return "Applied";
    case LearningOpportunityUserStatus.OfferedToLearner:
      return "Admission offer";
    case LearningOpportunityUserStatus.LearnerRejectedOffer:
      return "Offer declined";
    case LearningOpportunityUserStatus.AdmissionOfficerDeniedApplication:
      return "Denied";
    case LearningOpportunityUserStatus.OfferToLearnerExpired:
      return "Offer expired";
    case LearningOpportunityUserStatus.LearnerAttending:
      return "Attending";
    case LearningOpportunityUserStatus.LearnerCompleted:
      return "Passed";
    case LearningOpportunityUserStatus.LearnerDroppedOut:
      return "Dropped out";
    case LearningOpportunityUserStatus.LearnerFailed:
      return "Failed";
    default:
      Assert.notImplemented(`Unknown LearningOpportunityUserStatus = ${value}`);
      return "-";
  }
}

export const loTypeToString = (value: LearningOpportunityType): string => {
  switch (value) {
    case LearningOpportunityType.Challenge:
      return "Challenge";
    case LearningOpportunityType.MicroModule:
      return "Micro-module";
    default:
      Assert.notImplemented(
        `LearningOpportunityType '${value}' not recognized.`
      );
      return "-";
  }
};

export function getLevelString(level: Level): string {
  switch (level) {
    case Level.LevelAExplorer:
      return "Explorer";
    case Level.LevelBExpert:
      return "Expert";
    case Level.LevelCPioneer:
      return "Pioneer";
    default:
      Assert.notImplemented(`Unknown Level = ${level}`);
  }
  return "-";
}

export function getPaceString(pace: number | null): string {
  if (pace === null) {
    return "-";
  }
  return `${pace}%`;
}
